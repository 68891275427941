/* src/App.css */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.language-switch {
  position: absolute;
  top: 20px;
  right: 20px;
}

.input-section, .output-section {
  flex: 1;
  padding: 20px;
}

textarea {
  width: 100%;
  margin: 10px 0;
}

button {
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

h2 {
  margin-top: 20px;
}

p {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  text-align: left;
  width: 100%;
  margin: 10px 0;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
